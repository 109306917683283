<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}诊疗方案</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="标题" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="编号" prop="serialNumber">
				<el-input v-model.trim="ruleForm.serialNumber" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="简述" prop="description">
				<el-input v-model.trim="ruleForm.description" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="病症查询关联" prop="diseaseIdList">
				<el-select v-model="ruleForm.diseaseIdList" placeholder="请选择" :disabled="isShowDetail" multiple>
					<el-option v-for="item in diseaseList" :key="item.id" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="上传文件" prop="fileList" class="uploadDataList">
				<el-upload
					:class="{ hide: isShowDetail }"
					action=""
					multiple
					:before-upload="beforeUpload"
					:http-request="uploadURL"
					:on-error="importError"
					:limit="1"
					:file-list="ruleForm.fileList"
				>
					<el-button type="primary" icon="el-icon-upload2" :disabled="isShowDetail">上传文件(PDF)</el-button>
				</el-upload>
			</el-form-item>
			<el-form-item label="上传封面" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="MEDICAL_PRACTICING_CERTIFICATE"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				serialNumber: '',
				description: '',
				diseaseIdList: [],
				images: [],
				fileList: [],
			},
			diseaseList: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				serialNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				diseaseIdList: [{ required: true, message: '请选择', trigger: 'change' }],
				images: [{ required: true, message: '请上传', trigger: 'blur' }],
				fileList: [{ required: true, message: '请上传', trigger: 'blur' }],
			},
			attachmentInfoPhoto: {},
			attachmentInfoParams: {},
			ManufacturerList: [],
			saveLoading: false,
			link: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.getAttachmentGroups();
			this.getDiseaseList();
			this.link = data.link;
			if (data.row) {
				this.getDetails(data.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		getDetails(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 获取病症相关字典
		getDiseaseList() {
			let data = {
				size: 9999,
				current: 1,
				dictionaryCode: 'DISEASE_TYPE',
			};
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.diseaseList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			const { name, serialNumber, description, attachmentDtoMap, id, diseasesList } = data;
			this.ruleForm = {
				id,
				name,
				serialNumber,
				description,
				images: attachmentDtoMap['PHOTO'].map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				}),
				fileList: attachmentDtoMap['DOCUMENT'].map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
						name: item.title,
					};
				}),
				diseaseIdList: diseasesList && diseasesList.map((v) => v.id),
			};
		},
		// 获取图片附件组
		getAttachmentGroups() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'SMART_DEVICE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfoPhoto = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm() {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.ruleForm.fileList = [];
			this.$nextTick(() => {
				this.$refs.ruleForm.resetFields();
			});
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					const { images, fileList } = this.ruleForm;
					let imgArr = images.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfoPhoto.find((item) => item.code == 'PHOTO').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfoPhoto.find((item) => item.code == 'PHOTO').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let paramsArr = fileList.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfoPhoto.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfoPhoto.find((item) => item.code == 'DOCUMENT').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let params = {
						...this.ruleForm,
						attachmentDtoList: [...imgArr, ...paramsArr],
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
		// 自定义上传
		uploadURL(file) {
			let fileName = 'bernieri/zhangl/SMART_DEVICE_PARAMETER' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.ruleForm.fileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
						});
					}
				});
		},
		beforeUpload(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.pdf') {
				this.$message({
					type: 'error',
					message: '请导入pdf文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload {
	.el-button--primary {
		border-color: #c4c4c4;
		color: #333;
		background-color: #fff;
		margin-left: 0;
	}
	.is-disabled {
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		color: #c0c4cc !important;
	}
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
.hide {
	// display: none;
	/deep/ .el-upload-list {
		.el-icon-close,
		.el-icon-close-tip {
			display: none;
		}
	}
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>